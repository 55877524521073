
import React from 'react'

import '../styles/footer.sass'
import {Link} from "gatsby";

const Foot = () => (
    <footer>
        <div className="footer-container">
            Source Theme by <a href={'https://umisc.info'}>UMISC</a> • <Link to='/privacy'>Politique de vie privée</Link> • © Copyright PolyCyber
        </div>
    </footer>
)

export default Foot
