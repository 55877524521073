import React from 'react'

import {Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Footer from '../components/footer'

import '../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import {responsive} from '../utils/responsive'

const PrivacyPage = () => {
    return (<Layout>
        <SEO title="Politique de vie privée"/>
        <Banner class_suffix="deep"/>
        <div className="about-container">
            <div className="about-content">
                <header>
                    <h1>Politique de Confidentialité</h1>
                    <p><strong>Dernière mise à jour :</strong> 11 novembre 2024</p>
                </header>

                <section>
                    <h2>1. Identité et coordonnées du responsable du traitement</h2>
                    <p><strong>Nom de l'Organisation :</strong> PolyCyber</p>
                    <p><strong>Courriel de contact :</strong> <a href={"mailto:dpo@polycyber.io"}>dpo@polycyber.io</a></p>
                </section>

                <section>
                    <h2>2. Données personnelles collectées</h2>
                    <p>L'Organisation peut collecter les catégories de données personnelles suivantes :</p>
                    <ul>
                        <li><strong>Données d'identification :</strong> nom, prénom</li>
                        <li><strong>Coordonnées :</strong> adresse courriel, numéro de téléphone</li>
                        <li><strong>Données professionnelles :</strong> CV, lettre de motivation</li>
                    </ul>
                </section>

                <section>
                    <h2>3. Finalités du traitement des données</h2>
                    <p>Vos données personnelles sont collectées et traitées pour les finalités suivantes :</p>
                    <ul>
                        <li>Gérer les candidatures des participants (par exemple, analyse de CV) ;</li>
                        <li>Organiser des processus de sélection ou des événements liés aux activités de l'Organisation ;</li>
                    </ul>
                    <p>Toute autre utilisation sera soumise à votre consentement explicite.</p>
                </section>

                <section>
                    <h2>4. Base légale du traitement</h2>
                    <p>L'Organisation traite vos données sur les bases légales suivantes :</p>
                    <ul>
                        <li><strong>Consentement :</strong> pour les activités de traitement nécessitant une autorisation préalable, comme l'envoi de communications marketing.</li>
                        <li><strong>Intérêt légitime :</strong> pour améliorer nos processus de sélection et de recrutement.</li>
                    </ul>
                </section>

                <section>
                    <h2>5. Destinataires des données</h2>
                    <p>Vos données personnelles sont accessibles uniquement aux personnels autorisés de l'Organisation et, si nécessaire :</p>
                    <ul>
                        <li>Aux commandites liés à l’évaluation des candidatures ou à l’organisation d’événements ;</li>
                    </ul>
                </section>

                <section>
                    <h2>6. Durée de conservation des données</h2>
                    <p>Vos données personnelles seront conservées :</p>
                    <ul>
                        <li>Pour les CV et candidatures : un an.</li>
                        <li>Pour les finalités basées sur le consentement : jusqu'à ce que le consentement soit retiré.</li>
                    </ul>
                </section>

                <section>
                    <h2>7. Vos droits</h2>
                    <p>Conformément à la loi 25, vous disposez des droits suivants sur vos données :</p>
                    <ul>
                        <li><strong>Accès :</strong> obtenir une copie de vos données personnelles traitées ;</li>
                        <li><strong>Rectification :</strong> corriger des données inexactes ou incomplètes ;</li>
                        <li><strong>Effacement :</strong> demander la suppression de vos données, sous réserve de certaines conditions ;</li>
                        <li><strong>Opposition :</strong> vous opposer au traitement de vos données ;</li>
                    </ul>
                    <p>Pour exercer ces droits, contactez-nous à l’adresse : <a href={"mailto:dpo@polycyber.io"}>dpo@polycyber.io</a>.</p>
                </section>

                <section>
                    <h2>8. Sécurité des données</h2>
                    <p>L'Organisation met en œuvre des mesures techniques et organisationnelles pour protéger vos données contre tout accès non autorisé, perte, destruction ou divulgation accidentelle. Cela inclut :</p>
                    <ul>
                        <li>La sécurisation des systèmes informatiques et des serveurs ;</li>
                        <li>Des contrôles d’accès stricts pour les personnels autorisés ;</li>
                        <li>Le chiffrement ou la pseudonymisation des données sensibles.</li>
                    </ul>
                </section>

                <section>
                    <h2>9. Modification de la politique de confidentialité</h2>
                    <p>L'Organisation se réserve le droit de modifier cette politique à tout moment pour refléter les évolutions légales, réglementaires ou techniques. Toute mise à jour sera publiée sur <a href={"http://polycyber.io"}>polycyber.io</a>.
                    </p>
                </section>

                <section>
                    <h2>10. Contact et réclamations</h2>
                    <p>Pour toute question relative à cette politique, vous pouvez nous contacter à :</p>
                    <p><strong>Adresse e-mail :</strong><a href={"mailto:dpo@polycyber.io"}>dpo@polycyber.io</a></p>
                    <p>En cas de désaccord, vous avez le droit d’introduire une réclamation auprès de l’autorité de contrôle compétente :</p>
                    <p><strong>CAI</strong> (Québec) : <a href="https://www.cai.gouv.qc.ca">cai.gouv.qc.ca</a>.</p>
                </section>
            </div>
        </div>
    </Layout>)
}
export default PrivacyPage
